import { render, staticRenderFns } from "./EnergyObjectsTable.vue?vue&type=template&id=4768b3a9&scoped=true"
import script from "./EnergyObjectsTable.vue?vue&type=script&lang=js"
export * from "./EnergyObjectsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4768b3a9",
  null
  
)

export default component.exports